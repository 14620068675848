import React from 'react'
import { useSelector } from 'react-redux'
import { selectField } from '../heatmapSlice'
import gradients from '../gradients'

const GradientSelection = ({ onChange }) => {
    const gradient = useSelector(selectField('gradient'))

    return (
        <div className="form-group">
            <label forHtml="gradient">Color Scheme Selector</label>
            
            <select className="form-control" value={gradient} onChange={onChange} name="gradient" id="gradient">
                {gradients.map((g, i) => <option value={i} key={i}>{g}</option>)}
            </select>

            <small id="gradientHelp" class="form-text text-muted">
                Select a color scheme from different pre-defined ones. New color schemes can be defined in Unity.
            </small>
        </div>
    )
}

export default GradientSelection