import React from 'react';
import { setGroup } from "./behaviourControlsSlice";
import { useDispatch } from 'react-redux';

/**
 * Menu tab for a specific behaviour group.
 * @param {*} props 
 */
export const Group = ({ title, id, current }) => {
  let dispatch = useDispatch();

  return (
    <li className="nav-item">
      <button
        className={`nav-link ${id === current ? 'active' : ''}`}
        onClick={() => dispatch(setGroup(id))}
      >
        {title}
      </button>
    </li>
  )
}

export default Group