import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Slider from '../../../../components/Slider'
import { selectWeatherProperty, setWeatherProperty } from '../../classSlice'

const WeatherSlider = (props) => {
    const dispatch = useDispatch()
    const value = useSelector(selectWeatherProperty(props.name))

    const onChange = e => {
        dispatch(setWeatherProperty({ key: e.target.name, value: e.target.value }))
    }

    return (
        <div className="col">
            <Slider min={0} max={1} step={0.01} onChange={onChange} value={value} { ...props } />
        </div>
    )
}

export default WeatherSlider