import React from 'react'
import { useDispatch } from 'react-redux'
import { setField } from './heatmapSlice'

import ApiEndpoint from './components/ApiEndpoint'
import AuthToken from './components/AuthToken'
import QuerySelection from './components/QuerySelection'
import LoadDataButton from './components/LoadDataButton'
import PropertySelection from './components/PropertySelection'
import VisualizeButton from './components/VisualizeButton'
import ObjectList from './components/ObjectList'
import GradientSelection from './components/GradientSelection'

const DataLoader = () => {
    let dispatch = useDispatch()

    let onChange = e => dispatch(setField({ name: e.target.name, value: e.target.value }))

    return (
        <div className="container mt-4">
            <form>
                <div className="row">
                    <div className="col-lg"><ApiEndpoint onChange={onChange} /></div>
                    <div className="col-lg"><AuthToken onChange={onChange} /></div>
                </div>

                <div className="row">
                    <div className="col-lg"><QuerySelection onChange={onChange} /></div>
                    <div className="col-lg"><GradientSelection onChange={onChange} /></div>
                </div>
                
                <LoadDataButton />
                <PropertySelection onChange={onChange} />
                <ObjectList />
                <VisualizeButton />
            </form>
        </div>
    )
}

export default DataLoader