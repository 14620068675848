/**
 * Convert a single blob into base64 for upload through sockets.
 * @param {*} blob
 * @returns promise, which resolves once the file is converted
 */
export const blob2Base64 = async url => {
    let blob = await fetch(url).then(r => r.blob())

    let reader = new FileReader()
    reader.readAsDataURL(blob)

    return new Promise(resolve => {
        reader.onloadend = () => resolve(reader.result)
    })
}

/**
 * Asynchronously converts image urls into base64 strings.
 * @param {*} urls 
 * @returns list of base64 strings
 */
export const convertUrls = async urls => await Promise.all(urls.map(blob2Base64))