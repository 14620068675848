import React from 'react'
import PropTypes from 'prop-types'
import { behaviourColors } from './constants'
import { useDispatch, useSelector } from 'react-redux'
import { selectBehaviour, selectTheme, setBehaviour } from './behaviourControlsSlice'
import { findSelectedStudents } from '../classState/studentsSlice'

/**
 * Button which changes the currently selected behaviour.
 * @param {*} props 
 */
const BehaviourButton = ({ behaviour, type }) => {
  const dispatch = useDispatch()

  const currentBehaviour = useSelector(selectBehaviour)
  const currentTheme = useSelector(selectTheme)
  const students = useSelector(findSelectedStudents)

  type = type || "behaviour"

  let color = behaviourColors[behaviour]
  let active = behaviour === (type === "theme" ? currentTheme : currentBehaviour)

  return (
    <button
      className={`btn btn-${color} ${active && 'active'}`}
      type='button'
      onClick={() => dispatch(setBehaviour({ behaviour, type, students }))}
    >
      {behaviour}
    </button>
  )
}

BehaviourButton.propTypes = {
  /** Behaviour to switch to. */
  behaviour: PropTypes.string.isRequired,
  
  /** Type of the behaviour. */
  type: PropTypes.string
}


export default BehaviourButton
