import React, {useState} from 'react'
import {useTimer} from "react-timer-hook";
import "./Timer.css"
import {toHex} from "../behaviourControls/constants";


const Timer = ({onTimerExpired, className, style}) => {
    const [resetHours, SetHours] = useState(0)
    const [resetMinutes, SetMinutes] = useState(10)
    const [resetSeconds, SetSeconds] = useState(0)

    let totalSeconds = resetHours * 3600 + resetMinutes * 60 + resetSeconds

    let expireTimestamp = new Date()
    expireTimestamp.setSeconds(expireTimestamp.getSeconds() + totalSeconds)

    let RestartTimer = () => {
        let timer = new Date()
        timer.setSeconds(timer.getSeconds() + totalSeconds)
        restart(timer)
        if (!isRunning)
            pause()
    }

    let OnExpire = () => {
        onTimerExpired()
        RestartTimer()
    }

    let SetTime = (event, func) => {
        func(Math.max(parseInt(event.target.value), 0))
    }

    let {
        seconds, minutes, hours, isRunning, pause, resume, restart,
    } = useTimer({expiryTimestamp: expireTimestamp, onExpire: OnExpire, autoStart: false});


    return (<div className={"btn-group-vertical" + className} style={style}>
        <div className="h4 alert alert-info text-center mb-0 pt-1 pb-1" style={{width: "100%"}}>
            <span>{String(hours).padStart(2, '0')}</span>
            :
            <span>{String(minutes).padStart(2, '0')}</span>
            :
            <span>{String(seconds).padStart(2, '0')}</span>
        </div>
        <div className="btn-group px-0 py-0" style={{width: "100%"}}>
            <button className="btn btn-dark"
                    style={{width: "50%", background: isRunning ? toHex['warning'] : toHex['success']}}
                    onClick={isRunning ? pause : resume}>
                {isRunning ? 'Pause' : 'Start'}
            </button>
            <button className="btn btn-dark" style={{width: "50%", background: toHex['primary']}}
                    onClick={RestartTimer}>
                Reset
            </button>
        </div>

        <div className="h5">
            <span className="h6">
                    <input type="number" value={resetHours}
                           onChange={(event) => SetTime(event, SetHours)}/>H&nbsp;:&nbsp;
                </span>
            <span className="h6">
                <input type="number" value={resetMinutes}
                       onChange={(event) => SetTime(event, SetMinutes)}/>M&nbsp;:&nbsp;
            </span>
            <span className="h6">
                <input type="number" value={resetSeconds}
                       onChange={(event) => SetTime(event, SetSeconds)}/>S
            </span>
        </div>

    </div>);
};

export default Timer;