import React from 'react'
//import StudentState from './StudentState'

import BehaviourControls from '../behaviourControls/'
import ClassCanvas from '../classCanvas/ClassCanvas'

const ClassState = () => {
  return (
    <div>
      <div className='row'>
        <ClassCanvas />
      </div>

      <BehaviourControls />
    </div>
  )
}

export default ClassState
