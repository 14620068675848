import { configureStore } from '@reduxjs/toolkit'
import studentsReducer from '../features/classState/studentsSlice'
import websocketReducer from '../features/websocket/websocketSlice'
import scenarioReducer from '../features/scenario/scenarioSlice'
import canvasReducer from '../features/classCanvas/canvasSlice'
import scheduleReducer from '../features/schedule/scheduleSlice'
import behaviourControlsReducer from '../features/behaviourControls/behaviourControlsSlice'
import replayReducer from '../features/replay/replaySlice'
import weatherReducer from '../features/weather/weatherSlice'
import heatmapReducer from '../features/heatmap/heatmapSlice'
import classSelectionReducer from '../features/classSelection/classSlice'
import presentationReducer from '../features/presentation/presentationSlice'
import recordSlice from '../features/replay/recordSlice'

export default configureStore({
  reducer: {
    students: studentsReducer,
    websocket: websocketReducer,
    scenario: scenarioReducer,
    canvas: canvasReducer,
    schedule: scheduleReducer,
    behaviourControls: behaviourControlsReducer,
    replay: replayReducer,
    record: recordSlice,
    weather: weatherReducer,
    heatmap: heatmapReducer,
    classSelection: classSelectionReducer,
    presentation: presentationReducer
  },
});
