import React from 'react'
import { useDispatch } from 'react-redux'
import FileImporter from '../../components/FileImporter'
import ExportState from '../../components/ExportState'
import { loadClassConfig } from './classSlice'

const Header = () => {
    let dispatch = useDispatch()

    return (
        <div className="d-flex flex-row justify-content-start">  
        <ExportState className="btn btn-primary mr-2" selector={state => state.classSelection} /> 
            <FileImporter load={json => dispatch(loadClassConfig(json))} />         
        </div>
    )
}

export default Header