// TODO: Find a nicer way to import these
import { ReactComponent as fishbone } from './thumbnails/chairs/fishbone.svg'
import { ReactComponent as groupTables } from './thumbnails/chairs/group_tables.svg'
import { ReactComponent as horseshoe } from './thumbnails/chairs/horseshoe.svg'
import { ReactComponent as pairTables } from './thumbnails/chairs/pair_tables.svg'

import plasteredCourt from './thumbnails/exteriors/plastered_court.jpg'
import treeCourt from './thumbnails/exteriors/tree_court.jpg'
import defaultClass from './thumbnails/rooms/default_class.jpg'
import woodenClass from './thumbnails/rooms/wooden_class.jpg'
import labClass from './thumbnails/rooms/labroom.jpg'

import woodenFront from './thumbnails/front/wooden_front.jpg'
import whiteboardFront from './thumbnails/front/labfront.jpg'
import bigBlackboard from './thumbnails/front/bigblackboard.jpg'
import whiteboard from './thumbnails/front/whiteboard.jpg'
import beamer from './thumbnails/front/beamer.jpg'

export default {
    // list of all valid rooms
    room: [
        { id: "DefaultClassroom", name: "Default Class", src: defaultClass },
        { id: "SimpleRoom", name: "Wood Floor Class", src: woodenClass },
        { id: "LabRoom", name: "Lab Room", src: labClass }
    ],

    // options for the default room
    DefaultClassroom: {
        front: [
            { id: "Whiteboard Front", name:"Whiteboard", src: whiteboard },
            { id: "BigBlackboard", name: "Big Blackboard", src: bigBlackboard },
            { id: "BeamerFront", name: "Beamer", src: beamer }
        ],

        chairs: [
            { id: "Horseshoe", name: "Horseshoe", src: horseshoe, capacity: 30 },
            { id: "FrontalLecture", name: "Frontal Lecture", src: pairTables, capacity: 30 },
            { id: "GroupLecture", name: "Desk Groups", src: groupTables, capacity: 30 },
            { id: "Fishbone", name: "Fishbone Desks", src: fishbone, capacity: 30 }
        ]
    },

    SimpleRoom: {
        front: [
            { id: "SimpleFront", name: "Simple Front", src: woodenFront }
        ],

        chairs: [
            { id: "Horseshoe", name: "Horseshoe", src: horseshoe, capacity: 30 },
            { id: "FrontalLecture", name: "Frontal Lecture", src: pairTables,capacity: 30 },
            { id: "GroupLecture", name: "Desk Groups", src: groupTables, capacity: 30 },
            { id: "Fishbone", name: "Fishbone Desks", src: fishbone, capacity: 30 }
        ]
    },

    LabRoom: {
        front: [
            { id: "WhiteboardFront", name: "Whiteboard Front", src: whiteboardFront }
        ],

        chairs: [
            { id: "", name: "Pair tables", src: pairTables, capacity: 30 }
        ]
    },

    // this should be room independant, otherwise move into rooms
    outside: [
        { id: "PlasteredCourt", name: "Plastered Court", src: plasteredCourt },
        { id: "CourtWithTrees", name: "Meadow", src: treeCourt }
    ]
}
