import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectNormalized, selectObjects, toggleObject } from '../heatmapSlice'

const ObjectList = () => {
    const dispatch = useDispatch()
    const objects = useSelector(selectObjects)
    const normalized = useSelector(selectNormalized)

    return objects.length > 0 && (
        <div className="form-group">
            <label htmlFor="objectList">Object Selection</label>
            <small id="objectListHelp" class="form-text text-muted mb-2">
                Use this list to exclude objects from visualization. This should also affect normalization,
                so depending on the normalization method this might improve results.
            </small>
            <div style={{ overflowY: 'scroll', height: '400px' }}>
                <ul className="list-group" id="objectList">
                    {objects.map((item, i) => 
                        <button
                            className={`list-group-item ${item.enabled ? 'active' : ''}`}
                            type="button"
                            onClick={() => dispatch(toggleObject(i))} key={i}>
                                <b>{item.key}</b>, {item.value}
                        </button>)}
                </ul>
            </div>
        </div>
    )
}

export default ObjectList