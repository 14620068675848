import React from 'react'
import WeatherPatternsSelection from './WeatherPatternsSelection'
import WeatherSlider from './WeatherSlider'
import PrecipitationDirection from './PrecipitationDirection'

const WeatherCustomize = () => {

    return (
        <form className="mt-3">
            <WeatherPatternsSelection />

            <div className="form-row mt-3">
                <WeatherSlider name="Wetness" label="Wetness" description="Impacts how wet the terrain looks by making it more shiny."/>
                <WeatherSlider name="Opacity" label="Snow Strength" description="Influences the strength of the snow effect on terrain."/>
            </div>

            <PrecipitationDirection />
        </form>
    )
}

export default WeatherCustomize