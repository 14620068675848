import React from 'react'
import {evoke, impulses, themes} from '../constants'
import BehaviourButton from '../BehaviourButton'
import {useDispatch, useSelector} from 'react-redux'
import {
    selectActivateGlobalKnowledge,
    selectSkipIntro,
    setBehaviour,
    toggleGlobalKnowledge,
    toggleIntro
} from '../behaviourControlsSlice'

const ImpulseControls = () => {
    let intro = useSelector(selectSkipIntro)
    let globalKnowledge = useSelector(selectActivateGlobalKnowledge)
    let dispatch = useDispatch()

    return (
        <div>
            <div className="row mt-2">
                <div className="col">
                    <h4>Themes</h4>
                    <div className="form-check">
                        <input
                            className="form-check-input" type="checkbox" checked={intro} id="skipIntroCheck"
                            onClick={() => dispatch(toggleIntro())}
                        />
                        <label className="form-check-label" for="skipIntroCheck">
                            Skip Intro on theme change
                        </label>
                    </div>
                    <div className="form-check">
                        <input
                            className="form-check-input" type="checkbox" checked={globalKnowledge}
                            id="activateGlobalKnowledge"
                            onClick={() => dispatch(toggleGlobalKnowledge())}
                        />
                        <label className="form-check-label" for="activateGlobalKnowledge">
                            Class has common state in discussion
                        </label>
                    </div>

                    <select onChange={ev => dispatch(setBehaviour({
                        behaviour: ev.target.value,
                        type: "theme",
                        students: []
                    }))}>
                        <option value="">Thema auswählen</option>
                        {themes.map(b => (<option value={b}>{b}</option>))}
                    </select>
                </div>

                <div className="col">
                    <h4>Impulses</h4>
                    <div className={"btn-group-vertical my-2"}>
                        <div className="btn-group">
                            {impulses.map(i => <BehaviourButton behaviour={i} type="impulse"/>)}
                        </div>
                        <div className="btn-group">
                            {evoke.map(i => <BehaviourButton behaviour={i} type="impulse"/>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ImpulseControls