const dwell = encodeURIComponent(JSON.stringify([
    {
        "$match": { "statement.verb.id": { "$in": ["https://lrs.elearn.rwth-aachen.de/xAPI/verbs/focussed"] } }
    },
    { 
        "$project": {
            "object": "$statement.object.definition.name.en-US",
            "dwell": "$statement.result.extensions"
        }
    }
]))

const sumDwell = (objects) => {
    let props = {}

    objects.forEach(({ object, dwell }) => {
        Object.keys(dwell).forEach(key => {
            let value = dwell[key];

            if (!props[key]) props[key] = { id: key, data: {} }
            let data = props[key].data;

            if (!data[object]) data[object] = 0;
            
            data[object] += value;
        })
    })

    return Object.keys(props).map(k => ({ id: k, data: props[k].data }))
}

// Query options:
// - id is the string identifier for a query, mostly used in the UI
// - query is the aggregation query to be passed into the LRS
// - processing is a function which turns the query into a array
//   [ { id: "prop1", data: { obj1: value1, ob2: value2, ... } }, ... ]
export default [
    { id: "dwell", query: dwell, processing: sumDwell }
]