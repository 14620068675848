import { createSlice } from '@reduxjs/toolkit'
import { emitMsg, emit } from '../websocket/websocketSlice'

const initialState = {
  isRecording: false,
  recordingError: "",
  recording: undefined,

  recordFiles: [],
  startTimeRecording : [],
  currentReplay: '',
  currentStudentPerspective : null,
  currentRecordingTime: 0,
  currentReplayTime: 0,
  lengthReplay: 0,
  isLoading: false,
  tabToggle: false
}

// TO BE DONE: See if amount of reducers and states can be minimized
export const recordSlice = createSlice({
  name: 'recordManager',
  initialState: initialState,
  reducers: {
    throwError: (state, action) => {
      state.recordingError = action.payload
    },

    controlRecording: (state, action) => {
      state.isRecording = action.payload.recording
      emitMsg("recorderSignal", action.payload.signal)
    },

    receiveRecording: (state, action) => {
      state.recording = action.payload
    },

    clearRecording: (state, action) => {
      state.recording = undefined
    }
	/*
    init: (state, action) => {
      return action.payload
    },
    requestReplayFiles: (state, action) => {
      emit({ type: 'manageReplay', command: 'requestReplays' })
    },
    toggleTab: (state, action) => {
      state.tabToggle = action.payload
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setLengthReplay: (state, action) => {
      state.lengthReplay = action.payload
    },
    updateReplayFiles: (state, action) => {
      state.replayFiles = action.payload
    },
    updateCurrentReplay: (state, action) => {
      state.currentReplay = action.payload
    },
    tickCurrentRecordingTime: (state, action) => {
      state.currentRecordingTime += 1
    },
    tickCurrentReplayTime: (state, action) => {
      if(state.currentReplayTime < state.lengthReplay)
      {
        state.currentReplayTime += 1
      }
    },
    updateCurrentReplayTime: (state, action) => {
      state.currentReplayTime = parseInt(action.payload)
      emit({ type: 'manageReplay', command:'updateReplaytime', startTime : state.currentReplayTime})
    },
    startLoading: (state, action) => {
      state.isLoading = true
      state.currentReplayTime = 0
      emit({ type: 'manageReplay', command: 'startLoading', currentReplay: action.payload })
    },
    stopLoading: (state, action) => {
      state.isLoading = false
      state.currentReplayTime = 0
      emit({ type: 'manageReplay', command: 'stopLoading' })
    },
    pauseLoading: (state, action) => {
      state.isLoading = false
      emit({ type: 'manageReplay',  command: 'pauseLoading'})
    },
    continueLoading: (state, action) => {
      state.isLoading = true
      emit({ type: 'manageReplay',  command: 'continueLoading'})
    },
    setCurrentStudentPerspective: (state, action) => {
      state.currentStudentPerspective = action.payload.name
      emit({ type: 'manageReplay',  command: 'switchPerspective', id: action.payload.id }) // id used to find gameobject in unity
    },
    resetPerspective: (state, action) => {
      state.currentStudentPerspective = null
      emit({ type: 'manageReplay', command: 'resetPerspective'})
    }
	*/
  }
})

export const {
  throwError, controlRecording, receiveRecording, clearRecording,
  
  init, startRecording, stopRecording, abortRecording, toggleTab, setIsLoading, updateReplayFiles, setCurrentStudentPerspective, setLengthReplay,
  startLoading, updateCurrentReplay, tickCurrentRecordingTime, tickCurrentReplayTime, updateCurrentReplayTime, stopLoading, requestReplayFiles, pauseLoading, continueLoading, resetPerspective
  
} = recordSlice.actions;

export const selectIsRecording = ({ record }) => record.isRecording
export const selectError = ({ record }) => record.recordingError
export const selectRecording = ({ record }) => record.recording

export const selectReplayFiles = ({ record }) => record.replayFiles
export const selectCurrentReplay = ({ record }) => record.currentReplay
export const getRecordingState = ({ record }) => record.isRecording
export const getCurrentRecordingTime = ({ record }) => record.currentRecordingTime
export const getCurrentReplayTime = ({ record }) => record.currentReplayTime
export const getLengthReplay = ({ record }) => record.lengthReplay
export const getLoadingState = ({ record }) => record.isLoading
export const getToggleState = ({ record }) => record.tabToggle
export const getCurrentStudentPerspective = ({ record }) => record.currentStudentPerspective
export const getStartTimeRecording = ({ record }) => record.startTimeRecording

export default recordSlice.reducer

