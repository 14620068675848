import React from 'react'

const Slider = props => {
    const { label, description } = props

    return (
        <div className="form-group">
            <label>
                { label }
                <input type="range" className="form-control-range" { ...props } />
                { description && (
                    <small className="form-text text-muted">
                        {description}
                    </small>)}
            </label>
        </div>
    )
}

export default Slider