import { createSlice } from '@reduxjs/toolkit'
import { emit, emitMsg } from '../websocket/websocketSlice'

export const studentsSlice = createSlice({
  name: 'students',
  
  initialState: { students: [], noiseLevel: 0 },

  reducers: {
    /**
     * Initializes classroom.
     * @param {*} state 
     * @param {*} action payload contains list of students
     */
    init: (state, action) => {
      return { ...action.payload }
    },
    
    /**
     * Toggles the selection state of a student.
     * @param {*} state 
     * @param {*} action payload contains the students id
     */
    toggle: (state, action) => {
      let students = state.students.map(
        s => action.payload === s.id ? { ...s, selected: !s.selected } : s
      )

      state.students = students;
    },
    
    /**
     * Selects or deselects all students.
     * @param {*} state 
     * @param {*} action payload contains desired boolean state
     */
    selectAll: (state, action) => {
      let students = state.students.map(s => ({ ...s, selected: action.payload }))
      state.students = students
    },
    
    /**
     * Makes students behave in a certain way.
     * @param {*} state 
     * @param {*} action payload contains behaviour and list of students
     */
    behave: (state, action) => { // TODO: Used in scenario events. Necessary?
      let { behaviour, students } = action.payload
      emit({ type: 'behave', behaviour, students })

      return { ...state, students: state.students.map(s => students.includes(s.id) ? {...s, behaviour } : s) }
    },

    /**
     * Triggers some behaviour in students
     * @param {*} state 
     * @param {*} action payload specifies type, behaviour and affected students
     */
    triggerBehaviour: (state, action) => {
      switch (action.payload.type)
      {
        case 'impulse':
          emit({ // This seems a bit iffy to put into the reducer, but whatever
            type: 'impulseGiven',
            students: state.students.filter(s => s.selected).map(s => s.id),
            impulse: action.payload
          })

          state.students = state.map(
            s => s.selected ? { ...s, behaviour: action.payload, selected: false } : s
          )
          break;

        default:
          emit({ // This seems a bit iffy to put into the reducer, but whatever
            type: 'behave',
            students: state.students.filter(s => s.selected).map(s => s.id), behaviour: action.payload.behaviour,
          })
          state.students = state.students.map(
            s => s.selected ? { ...s, behaviour: action.payload.behaviour, selected: false } : s
          )
      }
    },

    askQuestions: (state, action) => {
      let { students, questions } = action.payload

      emit({ type: 'askQuestion', students, questions })
      
      state.students = state.students.map(
        s => s.selected ? { ...s, behaviour: "AskQuestion", selected: false } : s
      )
    },

    /**
     * Sets one student to a given behaviour (from Unity).
     * @param {*} state 
     * @param {*} action payload contains id and behaviour
     */
    syncBehaviour: (state, action) => {
      let students = state.students.map(
        s => s.id === action.payload.id ? { ...s, behaviour: action.payload.behaviour } : s
      )
      state.students = students;
    },

    changeNoiseLevel: (state, action) => {
        emitMsg("changeNoiseLevel", action.payload)

        state.noiseLevel = action.payload
    }
  }
})

export const {
  init, toggle, selectAll, triggerBehaviour, triggerImpulse, triggerTheme, syncBehaviour,
  behave, changeNoiseLevel, askQuestions
} = studentsSlice.actions;

export const selectStudents = ({ students }) => students.students
export const selectNoiseLevel = ({ students }) => students.noiseLevel

export const findSelectedStudents = ({ students }) => students.students.filter(s => s.selected)

export default studentsSlice.reducer
