import React from 'react';
import { goodBehaviours, badBehaviours } from '../../constants'
import BehaviourButton from '../../BehaviourButton'

import ToggleAll from './ToggleAll'
import ContextOptions from './ContextOptions'
import SendBehaviour from './SendBehaviour'

const BehaviourManagement = () => {
  return (
    <div>
      <div className="btn-group mt-2">
        {goodBehaviours.map(b => <BehaviourButton key={b} behaviour={b} />)}
      </div>
      <br/>
      <div className="btn-group mt-2">
        {badBehaviours.map(b => <BehaviourButton key={b} behaviour={b} />)}
      </div>

      <div className="mt-4" />
      <span className="h4">
        Action Details
        <span className='ml-3'><ToggleAll /></span>
        <SendBehaviour />
      </span>
      <ContextOptions />
    </div>
  )
}

export default BehaviourManagement
