import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setComponent, selectDirection } from '../../classSlice'

const VectorField = ({ component }) => {
    const dispatch = useDispatch()
    const value = useSelector(selectDirection)[component]

    const onChange = e => {
        const num = e.target.value.replace(/[^\d\.]/g, '')
        if (e.target.value.match(/^\d*\.?\d*$/)) {
            dispatch(setComponent({ component, value: e.target.value }))
        }
    }

    return (
        <input
            type="text"
            className="form-control col mr-2"
            placeholder={component}
            value={value}
            onChange={onChange} />
    )
}

const PrecipitationDirection = () => (
    <div className="form-group">
        <label>
            Direction of the precipitation:
            <div className="form-row mt-2">
                <VectorField component="x" />
                <VectorField component="y" />
                <VectorField component="z" />
            </div>
            <small className="form-text text-muted">
                Used to calculate the part of terrain that is covered in snow/wet.
                For reference, [0, 1, 0], where only y is one, is considered as
                precipitation from directly above.
            </small>
        </label>
    </div>
)

export default PrecipitationDirection