import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setContext, selectContext } from '../../../../behaviourControlsSlice'

const questionContext = (k, v) => setContext({ context: 'askQuestion', key: k, value: v })
const selectQuestionContext = selectContext('askQuestion')

// TODO: Get these from Unity somehow
const maleQuestions = [
    "kapier ich nicht-male", "frueher Schluss machen-male", "Herr Schmidt-male", "Pause machen-male"
]

const femaleQuestions = [
    "noch mal erklaeren-female", "wozu brauchen wir das-female", "Anton lenkt ab-female",
    "kann ich was essen-female", "Hunger-female"
]

const QuestionSelector = ({ student: { name, id, gender } }) => {
    let dispatch = useDispatch()
    let qNum = useSelector(selectQuestionContext)[id]

    let onClick = i => dispatch(questionContext(id, i))

    let questions = gender === "male" ? maleQuestions : femaleQuestions

    return (
        <div className="mt-1">
            <div className="btn-group mr-2">
                <button
                    className="btn btn-secondary dropdown-toggle" type="button"
                    ariaExpanded="false" data-toggle="dropdown"
                >
                    {questions[qNum] || "Please choose a question"}
                </button>
                <ul className="dropdown-menu">
                    {questions.map((text, i) =>
                        <li><button className="dropdown-item" onClick={() => onClick(i)}>{text}</button></li>)}
                </ul>
            </div>
            {name}
        </div>
    )
}

export default QuestionSelector