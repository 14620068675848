import { createSlice } from '@reduxjs/toolkit'
import { emit } from '../websocket/websocketSlice'

const behaviourControlsSlice = createSlice({
  name: "behaviourControls",

  initialState: {
    group: "behaviour",
    behaviour: undefined,
    type: undefined,
    theme: undefined,
	impulse: undefined,
    skipIntro: false,
	activateGlobalKnowledge: false,
    context: { askQuestion: {} },
  },

  reducers: {
    /**
     * Switches to the desired behaviour group.
     * @param {*} state 
     * @param {*} action payload is the desired group name
     */
    setGroup: (state, action) => ({ ...state, group: action.payload }),

    /**
     * Switches to the desired behaviour depending on type.
     * @param {*} state 
     * @param {*} action payload contains behaviour and it's type
     */
    setBehaviour: (state, action) => {
      let { behaviour, type } = action.payload
      
      switch (type) {
        case "theme":
          emit({
            type: 'themeChange',
            theme: behaviour,
            skip: state.skipIntro,
			globalKnowledge: state.activateGlobalKnowledge
          })
          return { ...state, theme: behaviour }

        case "impulse":
          emit({
            type: 'impulseGiven',
            impulse: behaviour,
            students: action.payload.students.map(student => student.id)
          })

          return { ...state, impulse: behaviour }
        
        default:
          return { ...state, behaviour, type }
        }
    },

    /**
     * Toggles intro option.
     * @param {*} state
     */
    toggleIntro: (state) => ({ ...state, skipIntro: !state.skipIntro }),
	toggleGlobalKnowledge: (state) => ({ ...state, activateGlobalKnowledge: !state.activateGlobalKnowledge }),

    setContext: (state, action) => {
      let context = state.context[action.payload.context]
      context[action.payload.key] = action.payload.value
    }
  }
})

export const { setGroup, setBehaviour, toggleIntro, toggleGlobalKnowledge, setContext } = behaviourControlsSlice.actions

/** Removes behaviour and type from the selection state. */
export const unsetBehaviour = () => setBehaviour({ behaviour: undefined, type: undefined })

export const selectCurrentGroup = state => state.behaviourControls.group
export const selectBehaviour = state => state.behaviourControls.behaviour
export const selectTheme = state => state.behaviourControls.theme
export const selectImpulse = state => state.behaviourControls.impulse
export const selectType = state => state.behaviourControls.type
export const selectSkipIntro = state => state.behaviourControls.skipIntro
export const selectActivateGlobalKnowledge = state => state.behaviourControls.activateGlobalKnowledge
export const selectContext = context => state => state.behaviourControls.context[context]

export default behaviourControlsSlice.reducer
