import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectLength, setTime, syncTime, selectTime } from './replaySlice'

const zeroPad = num => ("0" + num).slice(-2)

const toTime = length => {
    let time = Math.ceil(length)
    let m = Math.floor(time / 60)
    let s = time % 60
    
    return zeroPad(m) + ":" + zeroPad(s)
}

const ReplaySlider = () => {
    let dispatch = useDispatch()

    let length = useSelector(selectLength)
    let time = useSelector(selectTime)

    const sync = () => syncTime(time)
    const update = ev => dispatch(setTime(ev.target.value))

    return (
        <label>
            <input
                className="mr-2" type="range" min={0} max={Math.ceil(length)}
                onMouseUp={sync} onChange={update} value={time}
            />
            {toTime(time)}/{toTime(length)}
        </label>
    )
}

export default ReplaySlider