import React from 'react'
import { useSelector } from 'react-redux'
import { selectField } from '../heatmapSlice'

const ApiEndpoint = ({ onChange }) => {
    const url = useSelector(selectField('apiEndpoint'))

    return (
        <div className="form-group">
            <label forHtml="apiEndpoint">API Endpoint</label>
            <input
                type="text" class="form-control" value={url} placeholder="API Endpoint"
                name="apiEndpoint" onChange={onChange} id="apiEndpoint" />
            <small id="apiEndpointHelp" class="form-text text-muted">
                The API Endpoint is specified in Your Learning Locker, see <i>Settings &gt; Client</i>.
            </small>
        </div>
    )
}

export default ApiEndpoint