import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { changeNoiseLevel, selectNoiseLevel } from './studentsSlice'

const ClassroomAtmosphere = () => {
    let dispatch = useDispatch()
    let level = useSelector(selectNoiseLevel)

    let callback = i => () => dispatch(changeNoiseLevel(i))

    return (
        <span>
            Quiet
            <span className="btn-group mx-2" role="group" aria-label="Class atmosphere">
                {[0,1,2,3,4].map(i => (
                    <button
                        className={`btn ${i <= level ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={callback(i)}
                    >
                        {i}
                    </button>))}
            </span>
            Loud
        </span>
    )
}

export default ClassroomAtmosphere