import React from 'react'
import classes from './classes'
import Options from './Options'

const RoomSelection = ({ title, type }) => {
    return (
        <div>
            <h4>{title}</h4>
            <Options items={classes[type]} k={type} />
        </div>
    )
}

export default RoomSelection