import { createSlice } from '@reduxjs/toolkit'
import classes from './classes'

export const classSelectionSlice = createSlice({
    name: 'classSelection',
    initialState: {
        room: 0,
        front: 0,
        chairs: 0,
        outside: 0,
        studentCount: 30,
        messiness: 0,

        weatherKey: 'sunny',
        weather: {
            Weather: [],
            Wetness: 0,
            Opacity: 0,
            Direction: { x: 0, y: 1, z: 0 }
        }
    },
    reducers: {
        setProperty: (state, action) => {
            state[action.payload.key] = action.payload.value
        },

        setWeatherProperty: (state, action) => {
            state.weather[action.payload.key] = action.payload.value
        },

        loadClassConfig: (state, action) => ({ ...state, ...action.payload }),

        setWeather: (state, action) => {
            state.weather = action.payload
        },

        addPattern: (state, action) => {
            state.weather.Weather.push(action.payload)
        },

        removePattern: (state, action) => {
            state.weather.Weather = state.weather.Weather.filter(
                pattern => pattern != action.payload
            )
        },

        setComponent: (state, action) => {
            const { component, value } = action.payload
            state.weather.Direction[component] = value
        }
    }
})

export const {
    setProperty, loadClassConfig, setWeatherProperty, setWeather,
    addPattern, removePattern, setComponent
} = classSelectionSlice.actions

export const selectRoom = state => classes.room[state.classSelection.room]
export const selectOutside = state => classes.outside[state.classSelection.outside]
export const selectRoomFeatures = type => state => classes[selectRoom(state).id][type]
export const selectProperty = type => state => state.classSelection[type]
export const selectWeatherProperty = type => state => state.classSelection.weather[type]
export const selectDirection = state => state.classSelection.weather.Direction

export const selectCapacity = state => selectFeature("chairs")(state).capacity

export const selectFeature = feat => state => {
    let i = state.classSelection[feat]
    return classes[selectRoom(state).id][feat][i]
}

export const selectRoomParameters = state => ({
    ...state.classSelection,
    room: selectRoom(state).id,
    outside: selectOutside(state).id,
    front: selectFeature("front")(state).id,
    chairs: selectFeature("chairs")(state).id,
    students: selectProperty("studentCount")(state),
    weather: selectProperty("weather")(state)
})

export default classSelectionSlice.reducer