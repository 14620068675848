import React from 'react'
import { useSelector } from 'react-redux'
import { sendNormalized } from '../heatmapSlice'
import { emitMsg } from '../../websocket/websocketSlice'

function toggle() {
    emitMsg("heatmapToggle");
}

const VisualizeButton = () => {
    const send = useSelector(sendNormalized)

    return send && (
        <div className="d-flex justify-content-center">
            <button type="button" className="btn btn-primary" onClick={send}>
                Update Visualization
            </button>

            <button type="button" className="btn ml-2 btn-secondary" onClick={toggle}>
                Toggle Heatmap
            </button>
        </div>
    )
}

export default VisualizeButton