import React from 'react'
import { selectCurrentGroup } from "./behaviourControlsSlice"
import { useSelector } from 'react-redux'
import BehaviourManagement from './behaviourGroups/BehaviourManagement'
import ImpulseControls from './behaviourGroups/ImpulseControls'
import Group from './Group'


/**
 * Widget which groups all behaviour controls.
 */
const BehaviourControls = () => {
  let group = useSelector(selectCurrentGroup)

  return (
    <div>
      <ul className="nav nav-tabs">
        <Group title="Behaviour Management" id="behaviour" current={group} />
        <Group title="Impulse Controls" id="impulse" current={group} />
      </ul>
      <div>
        {group === "behaviour" && <BehaviourManagement />}
        {group === "impulse" && <ImpulseControls />}
      </div>
    </div>
  )
}

export default BehaviourControls
