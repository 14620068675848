import React from 'react'
import { useSelector } from 'react-redux'
import { selectRoomFeatures } from './classSlice'
import Options from './Options'

const RoomFeatureSelection = ({ title, type }) => {
    let room = useSelector(selectRoomFeatures(type))

    return (
        <div>
            <h4>{title}</h4>
            <Options items={room} k={type} />
        </div>
    )
}

export default RoomFeatureSelection