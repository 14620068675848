import React from 'react'
import PresentationUploader from './PresentationUploader'
import SlideControls from './SlideControls'

const PresentationHeader = () => (
    <div className="d-flex justify-content-between">
        <PresentationUploader />
        <SlideControls />
    </div>
)

export default PresentationHeader
