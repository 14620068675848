import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectPipeline } from '../heatmapSlice'

const LoadDataButton = () => {
    const dispatch = useDispatch()
    const loader = useSelector(selectPipeline)

    return (
        <div className="d-flex justify-content-center">
            <button type="button" onClick={() => loader(dispatch)} className="btn btn-primary">
                Load Data
            </button>
        </div>
    )
}

export default LoadDataButton