import React from "react";
import { useSelector } from "react-redux";
import AskQuestionContext from "./contextOptions/AskQuestionContext/";
import { selectBehaviour } from "../../behaviourControlsSlice";

const ContextOptions = () => {
  switch (useSelector(selectBehaviour)) {
    case "AskQuestion":
      return <AskQuestionContext />;
    default:
      return null;
  }
};

export default ContextOptions;
