import React from 'react'
import RoomFeatureSelection from '../features/classSelection/RoomFeatureSelection'
import RoomSelection from '../features/classSelection/RoomSelection'
import LoadClassButton from '../features/classSelection/LoadClassButton'
import StudentSlider from '../features/classSelection/StudentSlider'
import TrashSlider from '../features/classSelection/TrashSlider'
import Header from '../features/classSelection/Header'
import WeatherSelection from '../features/classSelection/WeatherSelection'

const ClassSelection = () => (
    <div className="mt-4">
        <Header />

        <RoomSelection type="room" title="Room" />

        <div className="mt-4" />
        <RoomSelection type="outside" title="Outside" />

        <div className="mt-4" />
        <RoomFeatureSelection type="front" title="Front Equipment" />
        
        <div className="mt-4" />
        <RoomFeatureSelection type="chairs" title="Chair Arrangement" />

        <div className="mt-4" />
        <StudentSlider />

        <div className="mt-4" />
        <TrashSlider />

        <div className="mt-4" />
        <WeatherSelection />

        <div className="mt-4" />
        <LoadClassButton />

        <div className="mt-4" />
    </div>
)

export default ClassSelection