import React from 'react'
import './sidebar.css'
import { Link, Switch, Route, HashRouter as Router } from 'react-router-dom'
import Hotkeys from './Hotkeys'

import * as pages from './pages'
import SchoolGong from "./features/schoolGong/SchoolGong";

const NavBar = () => (
  <nav className='navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow'>
    <Link className='navbar-brand col-md-3 col-lg-2 mr-0 px-3' to='/'>VR Classroom</Link>
    <button
      className='navbar-toggler position-absolute d-md-none collapsed'
      type='button'
      data-toggle='collapse'
      data-target='#sidebarMenu'
      aria-controls='sidebarMenu'
      aria-expanded='false'
      aria-label='Toggle navigation'
    >
      <span className='navbar-toggler-icon' />
    </button>
  </nav>
)

const NavLink = ({ to, children }) => {
  // TODO: Highlight active link
  //
  return (
    <li className="nav-item">
      <Link className={`nav-link`} to={to}>{children}</Link>
    </li>
  )
}

const SidebarMenu = () => (
  <nav id='sidebarMenu' className='col-md-3 col-lg-2 d-md-block bg-light sidebar collapse'>
      <div className='sidebar-sticky pt-3'>
          <ul className='nav flex-column' style={{height: "50%"}}>
              <NavLink to="/">Classroom Controls</NavLink>
              <NavLink to="/scenario">Scenario</NavLink>
              <NavLink to="/replay">Record/Replay</NavLink>
              <NavLink to="/Weather">Weather</NavLink>
              <NavLink to="/classSelection">Class Selection</NavLink>
              <NavLink to="/heatmap">Heatmap</NavLink>
              <NavLink to="/presentation">Presentation</NavLink>
          </ul>
          <div className={"px-3"}>
              <SchoolGong/>
          </div>
      </div>
  </nav>
)

const Main = () => (
    <Router>
    {/* Logic Components */}
    <Hotkeys />

    {/* Visual components */}
    <NavBar />
    <div className='container-fluid'>
      <div className='row'>
        <SidebarMenu />
        <main className='col-md-9 ml-sm-auto col-lg-10 px-md-4' role='main'>
          <Switch>
            <Route path="/" exact><pages.Dashboard /></Route>
            <Route path="/scenario" exact><pages.Scenario /></Route>
            <Route path="/Weather" exact><pages.Weather /></Route>
            <Route path="/replay" exact><pages.Replay /></Route>
            <Route path="/heatmap" exact><pages.Heatmap /></Route>
            <Route path="/classSelection" exact><pages.ClassSelection /></Route>
            <Route path="/presentation" exact><pages.Presentation/></Route>
          </Switch>
        </main>
      </div>
    </div>
  </Router>
)

export default Main
