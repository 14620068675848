import React from 'react'
import { useSelector } from 'react-redux'

import { findSelectedStudents } from '../../../../../classState/studentsSlice'
import QuestionSelector from './QuestionSelector'

const AskQuestionContext = () => {
    let students = useSelector(findSelectedStudents)

    return (
        <div className="mt-4">
            {students.map((s, i) => <QuestionSelector key={i} student={s} />)}
        </div>
    )
}

export default AskQuestionContext