import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Canvas from '../classCanvas/Canvas'
import { wasClicked } from '../classCanvas/canvasSlice'
import DrawStudent from '../classCanvas/DrawStudent'
import Teacher from '../classCanvas/Teacher'
import { selectStudents } from '../classState/studentsSlice'
import { emitMsg } from '../websocket/websocketSlice'
import { setObserver } from './replaySlice'

const ReplayCanvas = () => {
    const dispatch = useDispatch()
    const students = useSelector(selectStudents)
    const clickCheck = useSelector(wasClicked)

    const onClick = (x, z) => {
        const clicked = students.find(s => clickCheck(s, x, z))
        if (clicked) {
            dispatch(setObserver(clicked.id))
            emitMsg("setObserver", clicked.id)
        }
    }

    return (
        <Canvas id="replayCanvas" width={800} height={600} onClick={onClick}>
            <Teacher />
            {students.map(s => <DrawStudent key={s.id} student={s} />)}
        </Canvas>
    )
}

export default ReplayCanvas