import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { emitJson } from '../websocket/websocketSlice';
import { loadSlides, selectData } from './presentationSlice'
import { convertUrls } from './utils';

const loadImages = event => {
    event.preventDefault();

    const { files } = event.target;
    const localImageURLs = Array.from(files).map(file => window.URL.createObjectURL(file));

    return localImageURLs;
}

const UploadButton = () => {
    const data = useSelector(selectData)

    const callback = async () => {
        data.slides = await convertUrls(data.slides)
        data.slides = data.slides.map(s => s.replace(/^.+,/, ""))
        emitJson('loadPresentation', data)
    }

    return (
        <button className="btn btn-success" onClick={callback}>
                Upload
        </button>
    )
}

const PresentationUploader = () => {
    const dispatch = useDispatch()

    const callback = event => {
        let images = loadImages(event)
        dispatch(loadSlides(images))
    }

    return(
        <div className="input-group mr-4">
            <div className="custom-file mr-2"  style={{maxWidth: "300px"}}>
                <input type="file" className="custom-file-input" onChange={callback} multiple/>
                <label className="custom-file-label" forhtml="fileImport">Choose file for upload</label>
            </div>

            <UploadButton />
        </div>
    )
}

export default PresentationUploader