import React from 'react'
import { PresentationHeader, Slides } from '../features/presentation'

const Presentation = () => (
    <div className="row">
        <div className="my-2 mx-2">
           <PresentationHeader />
        </div>
        <Slides />
    </div>
)

export default Presentation
