import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectProperty, setProperty } from './classSlice'
import './options.css'

const ListItem = ({ name, src, active, onClick }) => {
    return (
        <div className="card option">
            <img src={src} class="card-img-top" height={128} />
            <div className={`card-body py-2 ${active ? 'active' : ''}`}>
                <h5 className="card-title mb-0">{name}</h5>
            </div>
            <a className="stretched-link" onClick={onClick} />
        </div>
    )
}

const Options = ({ items, k }) => {
    let dispatch = useDispatch()
    let key = useSelector(selectProperty(k))
    
    const onClick = i => () => dispatch(setProperty({ key: k, value: i }))

    return (
        <div className="card-columns">
            {items.map((feat, i) =>
                <ListItem key={i} name={feat.name} active={key === i} onClick={onClick(i)} src={feat.src} />)}
        </div>
    )
}

export default Options