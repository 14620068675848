import React from 'react'
import WeatherHeader from '../features/weather/WeatherHeader'


const Weather = () => (
    <div className="row">
        <WeatherHeader />
        <p>
            This version of the weather controller is currently embedded into most of the standard scenes. However, if you use the modular classroom system (Classroom Selection), make sure to set
            the weather options on the bottom of the class selection menu.
        </p>
        <div className="mt-3 container-fluid">
        </div>
    </div>
)


export default Weather
