import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ExportState from '../../components/ExportState'
import { selectIsRecording, selectError, controlRecording, selectRecording } from './recordSlice'

const abort = controlRecording({ recording: false, signal: "abort" })
const start = controlRecording({ recording: true, signal: "start" })
const stop = controlRecording({ recording: false, signal: "stop" })

const RecorderControls = () => {
    const dispatch = useDispatch()
    const isRecording = useSelector(selectIsRecording)
    const error = useSelector(selectError)
    const recording = useSelector(selectRecording)

    let type = isRecording ? 'btn-warning' : 'btn-success'
    let onClick = () => dispatch(isRecording ? stop : start)

    return (
        <div className="d-flex justify-content-end">
            { error !== "" && (<span className="alert alert-danger">{error}</span>) }
            <button className={`btn ${type}`} onClick={onClick}>
                {isRecording ? "Stop" : "Start" } Recording
            </button>
            <button
                className="btn btn-danger ml-4" disabled={!isRecording}
                onClick={() => dispatch(abort)}
            >
                Abort Recording
            </button>

            <ExportState selector={selectRecording} className={`ml-4 btn btn-info ${recording || "disabled"}`} />
        </div>
    )
}

export default RecorderControls