import { createSlice } from '@reduxjs/toolkit'
import { init, syncBehaviour } from '../classState/studentsSlice'
import { init as canvasInit } from '../classCanvas/canvasSlice'
import {updateReplayFiles, setLengthReplay, throwError, receiveRecording} from '../replay/recordSlice'
import { setPaused, setTime } from '../replay/replaySlice'
import { changeSlide } from '../presentation/presentationSlice'

export const websocketSlice = createSlice({
  name: 'websocket',
  initialState: {
    status: 'closed', // TODO other interesting states?
    error: '',
    connecting: false
  },
  reducers: {
    connectionState: (state, action) => ({ ...state, status: action.payload, connecting: false }),
    socketError: (state, action) => ({ ...state, error: action.payload }),
    tryConnect: (state, action) => ({ ...state, connecting: true })
  }
})

export const { connectionState, socketError } = websocketSlice.actions

let socket

/**
 * Sends a clear-text message to the socket.
 * @param {string} type message type/key
 * @param {string} message payload
 */
export const emitMsg = (type, message) => {
    console.log(`[socket] emit message of type ${type}`)
    socket.send(`${type};${message}`)
}

/**
 * Stringifies the payload object before sending it.
 * @param {string} type 
 * @param {object} payload
 */
export const emitJson = (type, payload) => emitMsg(type, JSON.stringify(payload))

/**
 * Stringifies a redux action before sending it.
 * @param {object} action 
 */
export const emit = action => emitJson(action.type, { ...action, type: undefined })

export const requestBootstrapping = () => {
  emit({ type: 'bootstrap' })
}

export const requestNextSlide = () => {
  console.log('here')
  emit({ type: 'presentation' })
}

class Teacher {
  Teacher() {
    this.pos = undefined
  }

  setTeacherPos(pos) {
    this.pos = pos
  }

  getTeacherPos() {
    return this.pos
  }
}

// Use class instead of redux to drastically reduce amount of actions
export const teacher = new Teacher()

const messageHandlers = dispatch => ({
  bootstrap: payload => { dispatch(init(payload)); dispatch(canvasInit(payload.students)) },
  //syncPresentation : ({current_slide, timing, overalltime})=> { dispatch(updateSlide(current_slide));dispatch(updateTime(timing));dispatch(updateOverallTime(overalltime))},
  behave: student => dispatch(syncBehaviour(student)),
  syncTeacher: pos => teacher.setTeacherPos(pos),
  replay: replayInfoUnity => dispatch(updateReplayFiles(replayInfoUnity.replayNames)),
  setReplayLength: replayInfoUnity => dispatch(setLengthReplay(replayInfoUnity.replayLength)),
  
  submitRecording: json => dispatch(receiveRecording(json)),
  recordingError: json => dispatch(throwError(json)),
  replaySync: ({ message }) => dispatch(setTime(parseFloat(message))),
  replayState: ({ message }) => dispatch(setPaused('pause' === message)),
  syncSlide: ({ message }) => dispatch(changeSlide(parseInt(message)))
})

const handleMessage = (action, dispatch) => {
  const handler = messageHandlers(dispatch)[action.type]

  if (handler) {
    handler(action.payload)
  } else {
    dispatch(socketError('Unbekannter Aktionstyp ' + action.type))
    dispatch(connectionState('warning'))
  }
}

export const initSocket = (retry = false) => dispatch => {
  if (socket && !retry) return // Don't run this twice

  socket = new WebSocket("ws://localhost:10000/SockServer")
  dispatch(tryConnect())

  socket.onopen = e => {
    console.log("[socket] Connection established!")
    dispatch(connectionState('connected'))
    requestBootstrapping()
  }

  socket.onmessage = e => {
    const action = JSON.parse(e.data)
    handleMessage(action, dispatch)
  }

  socket.onerror = e => {
    dispatch(connectionState('error'))
    dispatch(socketError(e.message))
  }

  socket.onclose = e => {
    dispatch(connectionState('closed'))
  }
}

export const { tryConnect } = websocketSlice.actions

export const statusSelector = ({ websocket }) => websocket.status
export const errorSelector = ({ websocket }) => websocket.error
export const isConnectingSelector = ({ websocket }) => websocket.connecting

export default websocketSlice.reducer
