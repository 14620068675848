import { useEffect } from 'react'
import Mousetrap from 'mousetrap'
import { useDispatch, useSelector } from 'react-redux'
import { findSelectedStudents } from './features/classState/studentsSlice'
import { setBehaviour } from './features/behaviourControls/behaviourControlsSlice'


const Hotkeys = () => {
	const dispatch = useDispatch()
	const students = useSelector(findSelectedStudents)
	const state = 
  useEffect(() => {
    Mousetrap.bind("q", () => { dispatch(setBehaviour({ behaviour: 'positive', type: 'impulse', students })) })
	Mousetrap.bind("w", () => { dispatch(setBehaviour({ behaviour: 'neutral', type: 'impulse', students })) })
	Mousetrap.bind("e", () => { dispatch(setBehaviour({ behaviour: 'negative', type: 'impulse', students })) })
	Mousetrap.bind("r", () => { dispatch(setBehaviour({ behaviour: 'evoke', type: 'impulse', students })) })
  })

  return null
}

export default Hotkeys
