import React from 'react'

const readJson = load => e => 
    e.target.files[0].text().then(json => load(JSON.parse(json)))

const FileImporter = ({ load, accepted }) => {
  accepted = accepted || 'text/json'

  return (
    <span className="input-group">
      <span className="custom-file" style={{maxWidth: "300px"}}>
        <input
          type="file" className="custom-file-input" id="fileImport"
          onChange={readJson(load)}
          accept={accepted}
        />
        <label className="custom-file-label" forhtml="fileImport">Choose file to import</label>
      </span>
    </span>
  )
}

export default FileImporter
