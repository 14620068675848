import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectWeatherProperty, addPattern, removePattern } from '../../classSlice'

const patterns = [
    "rain", "snow", "lightning"
]

const WeatherPattern = ({ item }) => {
    const patterns = useSelector(selectWeatherProperty('Weather'))
    const dispatch = useDispatch()

    const active = patterns.includes(item) ? "active" : ""

    return (
        <button
            className={`list-group-item list-group-item-action ${active}`}
            type="button"
            onClick={() => dispatch(!active ? addPattern(item) : removePattern(item))}
        >
            { item }
        </button>
    )
}

const WeatherPatternsSelection = () => (
    <>
        <label for="weatherPatterns">Weather Patterns</label>
        <ul className="list-group">
            { patterns.map((p, i) => <WeatherPattern item={p} key={i} />) }
        </ul>
        <small className="form-text text-muted">
            Allows to select different parts of the weather system. Each option
            activates the respective weather patterns in the loaded scene.
        </small>
    </>
)

export default WeatherPatternsSelection