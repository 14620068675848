import React from 'react'
import { useSelector } from 'react-redux'
import { selectProperty } from '../classSlice'
import WeatherPicker from './WeatherPicker'
import WeatherCustomize from './WeatherCustomize'

export const weatherPatterns = [
    {
        id: 'sunny',
        title: 'Clear',
        weather: {
            Weather: [],
            Wetness: 0,
            Opacity: 0,
            Direction: { x: 0, y: 1, z: 0 }
        }
    },
    {
        id: 'rainy',
        title: 'Rain',
        weather: {
            Weather: ['rain'],
            Wetness: 1,
            Opacity: 0,
            Direction: { x: 0, y: 1, z: 0 }
        }
    },
    {
        id: 'snowy',
        title: 'Snow',
        weather: {
            Weather: ['snow'],
            Wetness: 0,
            Opacity: 1,
            Direction: { x: 0, y: 1, z: 0 }
        }
    },
    {
        id: 'stormy',
        title: 'Storm',
        weather: {
            Weather: ['rain', 'lightning'],
            Wetness: 1,
            Opacity: 0,
            Direction: { x: 0, y: 1, z: 1 }
        }
    },
    {
        id: 'custom',
        title: 'Custom Weather',
        weather: {
            Weather: [],
            Wetness: 0,
            Opacity: 0,
            Direction: { x: 0, y: 1, z: 0 }
        }
    }
]

const WeatherSelection = () => {
    const selection = useSelector(selectProperty('weatherKey'))

    return (
        <div>
            <h4>Weather</h4>
            <WeatherPicker />
            { selection == 'custom' && <WeatherCustomize />}
        </div>
    )
}

export default WeatherSelection