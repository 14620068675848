import Timer from "./Timer";
import React from "react";
import {emitJson} from "../websocket/websocketSlice";

const SchoolGong = () => {
    let PlayChime = () => {
        emitJson("playSound", {sound: "chime"})
    }

    return (
        <div>
            <h3>School Gong</h3>
            <div className="btn-group px-0 py-0 my-2">
                <Timer onTimerExpired={PlayChime} className="mt-2" style={{width: "100%"}}></Timer>
                <button className="btn btn-dark" onClick={PlayChime}>
                    Play Gong
                </button>
            </div>
        </div>
    )
}

export default SchoolGong