import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { statusSelector } from '../websocket/websocketSlice'
import "./styling.css"

import {
  raining, sunny, snowing
} from "./weatherSlice"


const WeatherHeader = () => {
    let dispatch = useDispatch()
    return (
        <>
            <div className="container">
                <button className="btn btn-warning mr-5" onClick={() => dispatch(sunny())}>
                    Sunny
      </button >
                <button className="btn btn-primary mr-5" onClick={() => dispatch(raining())}>
                    Raining
     </button>
                <button className="btn btn-light mr-5" onClick={() => dispatch(snowing())}>
                    Snowing
     </button>
            </div>

            
        </>
    )
}






export default WeatherHeader


