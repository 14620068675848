import React from 'react'
import { useSelector } from 'react-redux'
import { selectField } from '../heatmapSlice'

const PropertySelection = ({ onChange }) => {
    const property = useSelector(selectField('property'))
    const data = useSelector(selectField('data'))

    return data !== undefined && (
        <div className="form-group">
            <label htmlFor="property">
                Property to be visualized
            </label>
            <select
                className="form-control" value={property} onChange={onChange}
                name="property" id="property">
                {data.map((val, i) => <option key={i} value={i}>{val.id}</option>)}
            </select>
            <small id="propertyHelp" class="form-text text-muted">
                Properties are entirely query-dependant and may be for instance xAPI atoms.
            </small>
        </div>
    )
}

export default PropertySelection