import React from 'react'
import { useSelector } from 'react-redux'
import { selectProperty, setProperty } from '../classSlice'
import WeatherItem from './WeatherItem'
import { weatherPatterns } from './'

const WeatherPicker = () => {
    const selection = useSelector(selectProperty("weatherKey"))

    return (
        <div className="dropdown">
            <button
                className="btn btn-secondary dropdown-toggle" type="button" id="weatherDropdown"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
            >
                { weatherPatterns.find(p => p.id == selection).title }
            </button>
            <div className="dropdown-menu" aria-labelledby="weatherDropdown">
                { weatherPatterns.map(p => <WeatherItem { ...p } />) }
            </div>
        </div>
    )
}

export default WeatherPicker