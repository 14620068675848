import React from 'react'
import { useDispatch } from 'react-redux'
import FileImporter from '../../components/FileImporter'
import { load } from './replaySlice'
import ReplaySlider from './ReplaySlider'
import PlayButton from './PlayButton'

const ReplayControls = () => {
    const dispatch = useDispatch()

    return (
        <div className="mt-2 container-fluid">
            <div className="row">
                <FileImporter load={load(dispatch)} />
            </div>
            <div className="row">
                <PlayButton />
                <ReplaySlider />
            </div>
        </div>
    )
}

export default ReplayControls