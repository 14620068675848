import { createSlice } from '@reduxjs/toolkit'
import { emit, emitMsg } from '../websocket/websocketSlice'

const initialState = {
    length: 0,
    isPaused: true,
    time: 0,
    observer: "",
}

export const replaySlice = createSlice({
    name: 'replayManager',
    initialState: initialState,
    reducers: {
        setLength: (state, action) => {
            state.length = action.payload
        },

        setTime: (state, action) => {
            state.time = action.payload
        },

        setPaused: (state, action) => {
            state.isPaused = action.payload
        },

        setObserver: (state, action) => {
            state.observer = action.payload
        }
    }
})

export const { setLength, setTime, setPaused, setObserver } = replaySlice.actions

export const selectLength = ({ replay }) => replay.length
export const selectPaused = ({ replay }) => replay.isPaused
export const selectTime = ({ replay }) => replay.time
export const selectObserver = ({ replay }) => replay.observer

export const pause = setPaused(true)
export const play = setPaused(false)

export const syncTime = time => {
    emitMsg("replaySync", time)
}

export const load = dispatch => json => {
    emit({ type: "loadReplay", ...json })
    dispatch(setLength(json.length))
    dispatch(setTime(0))
    dispatch(setPaused(true))
}

export default replaySlice.reducer