import React from 'react'
import { useSelector } from 'react-redux'

// TODO: might not work for larger files?
const tohref = json => "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(json))
    
const ExportState = props => {
    let json = useSelector(props.selector)
    
    let cleanedProps = Object.assign({}, props, { selector: undefined, title: undefined, json: undefined })

    return (
        <a href={tohref(json)} download={ props.title || "export.json" } { ...cleanedProps } >
            Export
        </a>
    )
}

export default ExportState