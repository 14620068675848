import React from 'react'
import { useSelector } from 'react-redux'
import { selectField } from '../heatmapSlice'
import queries from '../queries'

const QuerySelection = ({ onChange }) => {
    const query = useSelector(selectField('query'))

    return (
        <div className="form-group">
            <label forHtml="query">Query Selector</label>
            
            <select className="form-control" value={query} onChange={onChange} name="query" id="query">
                {queries.map((q, i) => <option value={i} key={i}>{q.id}</option>)}
            </select>

            <small id="queryHelp" class="form-text text-muted">
                Determines what kind of data is aggregated from the LRS.
                Currently, new queries can only be added via code.
            </small>
        </div>
    )
}

export default QuerySelection