import React from 'react'
import { useSelector } from 'react-redux'
import { selectField } from '../heatmapSlice'

const AuthToken = ({ onChange }) => {
    const auth = useSelector(selectField('auth'))
    
    return (
        <div className="form-group">
            <label forHtml="auth">Basic Auth Token</label>
            <input
                type="text" class="form-control" value={auth} placeholder="Auth Token"
                name="auth" onChange={onChange} id="auth" />
            <small id="authHelp" class="form-text text-muted">
                Can be found in the client specification under <i>Settings &gt; Client</i>.
                You might need to create a new client if none exist!
            </small>
        </div>
    )
}

export default AuthToken