import React from 'react'
import { useDispatch } from 'react-redux'
import { setProperty, setWeather } from '../classSlice'

const WeatherItem = ({ id, title, weather }) => {
    const dispatch = useDispatch()
    
    const onClick = () => {
        dispatch(setProperty({ key: 'weatherKey', value: id }))
        dispatch(setWeather(weather))
    }
    
    return (
        <button className="dropdown-item" type="button" onClick={onClick}>
            { title }
        </button>
    )
}

export default WeatherItem