import React from 'react'
import ReplayControls from '../features/replay/ReplayControls'
import ReplayCanvas from '../features/replay/ReplayCanvas'
import RecorderControls from '../features/replay/RecorderControls'

const Replay = () => (
  <div className="row mt-2">
    <RecorderControls />
    <ReplayControls />
    <ReplayCanvas />
  </div>
)


export default Replay
