import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectProperty, setProperty, selectCapacity } from './classSlice'

const StudentSlider = () => {
    let dispatch = useDispatch()
    let students = useSelector(selectProperty('studentCount'))
    let capacity = useSelector(selectCapacity)

    let onChange = ev => dispatch(setProperty({ key: 'studentCount', value: ev.target.value }))

    return (
        <div>
            
            <label forHtml="student-slider">
                Number of Students: {students}
            </label>
            <br />
            <input
                id="student-slider" type="range" min={0} max={capacity}
                onChange={onChange} value={students}
            />
        </div>
    )
}

export default StudentSlider