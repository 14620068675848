import React from 'react'
import { useSelector } from 'react-redux'
import { selectRoomParameters } from './classSlice'
import { emit } from '../websocket/websocketSlice'


const LoadClassButton = () => {
    const parameters = useSelector(selectRoomParameters)
    const onClick = () => emit({ type: "classChange", ...parameters })

    return (
        <button className="btn btn-lg btn-success mt-4" onClick={onClick}>
            Load Class
        </button>
    )
}

export default LoadClassButton