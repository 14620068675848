export const behaviourColors = {}
export const impulseColor = {}
export const themeColor = {}

export const toHex = {
  'light': '#F7F7f7',
  'success': '#5CB85C',
  'info': '#5BC0DE',
  'warning': '#F0AD4E',
  'danger': '#D9534F',
  'primary': '#0275D8'
}

export const NEUTRAL = 'light'
export const neutral = ['Idle']
neutral.forEach(b => behaviourColors[b] = NEUTRAL)

export const GOOD = 'success'
export const good = ['Writing', 'RaiseArm', 'AskQuestion', 'PutOnGoggles', 'StartNoisyFlame', 'TurnOffBunsenBurner', 'HeatUpSubstance', 'Typing']
good.forEach(b => behaviourColors[b] = GOOD)

export const WARNING = 'warning'
export const warning = ['Eating', 'Drinking', 'Playing', 'Staring', 'Tapping']
warning.forEach(b => behaviourColors[b] = WARNING)

export const DANGER = 'danger'
export const bad = ['Hitting', 'Throwing', 'Chatting', 'HeatUpSubstanceWrong', 'FineKnobToMainKnob', 'UseLighterExplosion']
bad.forEach(b => behaviourColors[b] = DANGER)

export const IMPULSE = 'info'
export const impulses = ['positive', 'neutral', 'negative']
behaviourColors['positive'] = GOOD;
behaviourColors['neutral'] = WARNING;
behaviourColors['negative'] = DANGER;

export const evoke = ['evoke']
behaviourColors['evoke'] = IMPULSE;


export const THEME_SELECTION = 'primary'
export const themes = [
  'Bismarck', 'Wende', 'achterMai', 'Bunsenbrenner','Nachfrageverschiebung','Französisch1','Französisch2','Französisch3','Französisch4','Französisch5','Spanisch1','Spanisch2','Spanisch3','Spanisch4','Spanisch5',
  'Englisch_Unterricht_1', 'Englisch_Unterricht_2', 'Englisch_Unterricht_3', 'Englisch_Unterricht_4', 'Englisch_Unterricht_5', 'Englisch_Unterricht_6', 
]
themes.forEach(b => behaviourColors[b] = THEME_SELECTION)

// Export behaviour labels in two different categories (maybe use map here instead?)
export const goodBehaviours = [ ...neutral, ...good ]
export const badBehaviours = [ ...warning, ...bad ]
export const impulseArray = [ ...impulses ]
export const themeArray = [ ...themes ]
export const allBehaviours = [ ...goodBehaviours, ...badBehaviours ]
