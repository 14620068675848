import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrent, selectSlides } from './presentationSlice'

const Slide = ({ url, i }) => {
    let current = useSelector(selectCurrent)
    return (
        <div className="card col-xl-2 col-md-4 col-12 px-0">
            <img src={url} className="card-img-top" alt="" style={{ height: "200px" }}/>
            <div className={`card-body ${(i - 1) === current ? "bg-primary" : ""}`}>
                <h5 className="card-title">{i}</h5>
            </div>
        </div>
    )
}

const Slides = () => {
    const slides = useSelector(selectSlides)

    return (
        <div className="row">
            {slides.map((url, i) => (<Slide url={url} i={i + 1} key={i} />))}
        </div>
    )
}

export default Slides