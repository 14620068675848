import { createSlice } from '@reduxjs/toolkit'
import { act } from 'react-dom/test-utils'
import { emit } from '../websocket/websocketSlice'


export const weatherSlice = createSlice({
  name: 'weatherManager',
  initialState: { isSunny: true, isSnowing : false,  isRaining: false},
  reducers: {
    init: (state, action) => {
      return action.payload
    },
    sunny: (state, action) => {
        state.isSunny = true
        state.isSnowing = false;
        state.isRaining = false;
        state.isSunny = act.payload;
      emit({ type: 'manageWeather', command: 'sunny' })
    },
    snowing: (state, action) => {
        state.isSunny = false;
        state.isSnowing = true;
        state.isRaining = false;
        emit({ type: 'manageWeather', command: 'snowing' })
      },

    raining: (state, action) => {
        state.isSunny = false;
        state.isSnowing = true;
        state.isRaining = false;
        emit({ type: 'manageWeather', command: 'raining' })
    },
   
  }
})

export const {
    init, sunny, snowing, raining
} = weatherSlice.actions;


export default weatherSlice.reducer

