import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    slides: [],
    aspect: 4.0 / 3.0,
    current: 0
}

export const presentationSlice = createSlice({
    name: 'presentation',
    initialState,
    reducers: {
        loadSlides: (state, action) => { state.slides = action.payload },
        changeSlide: (state, action) => { state.current = action.payload }
    }
})

export const { loadSlides, changeSlide } = presentationSlice.actions

/**
 * Selects a list of all current slides.
 * @param {*} state 
 * @returns 
 */
export const selectSlides = ({ presentation }) => presentation.slides;

/**
 * Selects the index of the currently selected slide.
 * @param {*} state 
 * @returns 
 */
export const selectCurrent = ({ presentation }) => presentation.current;

/**
 * Selects the URL of the currently selected slide.
 * @param {*} state 
 * @returns 
 */
export const selectCurrentSlide = state => selectSlides(state)[selectCurrent(state)];

/**
 * Extracts infos from store which will be used to initialize
 * a presentation in the application.
 * @param {*} state 
 * @returns 
 */
export const selectData = ({ presentation }) => ({
    slides: presentation.slides,
    aspect: presentation.aspect
})

export const selectSlideCount = state => selectSlides(state).length

/*



export const presentationSlice = createSlice({
  name: 'presentation',
  initialState: { files: [], file_names: [], current_slide: 0 , time: [], overallTime: 0},
  reducers: {
    init: (state, action) => {
      return action.payload
    },
    addPresentationSlide: (state,{payload: {file, file_name}}) => {
      //const files = state.files.map(f => ({ ...f, time: e.time - 1 }))
      
      const files = [...state.files,file]//.push(file)
      const file_names = [...state.file_names,file_name]//.push(file_name)
      return {...state,files : files,file_names: file_names}
    
    },
    triggerNextSlide: (state,action) => {
        console.log("Emit Next Slide")
        emit({ // This seems a bit iffy to put into the reducer, but whatever
          type: 'presentation',
          command: 'next'
        })
        return action.payload
      },
      triggerPreviousSlide: (state,action) => {
        emit({ // This seems a bit iffy to put into the reducer, but whatever
          type: 'presentation',
          command: 'previous'
        })
        return action.payload
      },
      clearPresentation: (state,action) => {
        emit({ // This seems a bit iffy to put into the reducer, but whatever
          type: 'presentation',
          command: 'clear'
        })
        return {files: [], file_names: [], current_slide: 0 , time: [], overallTime: 0}},
      startPresentation: (state,action) => {
        emit(
          {
            type: 'presentation',
            command: 'load',
            slides: state.files
          }
        )
        return state;
      },
      updateSlide: (state,current_slide) => {
       //console.log("Current silde is: " +current_slide)
       return { ...state,
        current_slide: current_slide.payload}
      },
      updateTime: (state,time) => {
        console.log("Current time is: " +time)
        //const time = [...state.time,time]
        return { ...state,
         time: time.payload}
       },
       updateOverallTime: (state,time) => {
        return { ...state,
          overallTime: time.payload}
        
       }


      
  }
})
export const {
    init, triggerNextSlide, triggerPreviousSlide, addPresentationSlide,clearPresentation,startPresentation,updateSlide, updateTime, updateOverallTime
  } = presentationSlice.actions;
  */
export default presentationSlice.reducer
