import { createSlice } from '@reduxjs/toolkit'
import { StaticRouter } from 'react-router-dom'
import { emit } from '../websocket/websocketSlice'
import queries from './queries'
import gradients from './gradients'

export const heatmapSlice = createSlice({
    name: 'heatmap',
    initialState: {
        query: 0,
        property: 0,
        auth: "Basic MGRhZGM1MmRlNjEwOTljOWI0MWNhNGQ0OGU4ZjNmZjlmY2FlNmY5Yzo3YzA3M2U5YzIzNWU4MGU4OWVmODZmZGQ5YTRkNzJlMjEyOGIxOTY2",

        // API endpoint of the LRS
        apiEndpoint: "https://lrs.elearn.rwth-aachen.de/api/",

        data: undefined,

        gradient: 0,

        enabled: [],
    },
    reducers: {
        setQuery: (state, action) => {
            state.query = action.payload
            state.data = []
        },
        setProperty: (state, action) => {
            state.property = action.payload
            state.enabled = state.data[state.property].map(_ => true)
        },
        setField: (state, action) => ({ ...state, [action.payload.name]: action.payload.value }),
        loadData: (state, action) => {
            state.data = queries[state.query].processing(action.payload)
            state.property = 0
            state.enabled = Object.keys(state.data[0].data).map(_ => true)
        },
        toggleObject: (state, action) => {
            state.enabled[action.payload] = !state.enabled[action.payload]
        }
    }
})

export const { setQuery, setProperty, setField, loadData, toggleObject } = heatmapSlice.actions

export const selectPipeline = ({ heatmap }) => {
    let query = queries[heatmap.query].query

    var url = heatmap.apiEndpoint
    url += "statements/aggregate/?cache=false&maxTimeMS=5000&maxScan=10000&pipeline=";
    url += query
    
    var obj = {
        method: "GET",
        headers: { 'Authorization': heatmap.auth }
    }

    return async (dispatch) => {
        let resp = await fetch(url, obj);
        let data = await resp.json();
        dispatch(loadData(data));
    }
}

export const selectField = name => state => state.heatmap[name]

export const selectObjects = ({ heatmap }) => {
    if (!heatmap.data) return []
    let res = []

    let objs = heatmap.data[heatmap.property].data
    let keys = Object.keys(objs)

    for (let i = 0; i < heatmap.enabled.length; i++)
    {
        res.push({ id: i, enabled: heatmap.enabled[i], key: keys[i], value: objs[keys[i]] })
    }

    return res
}

export const selectNormalized = ({ heatmap: { data, property, enabled } }) => {
    if (!data) return []

    let objs = data[property].data

    objs = Object.keys(objs)
        .map(Key => ({ Key, Value: objs[Key] }))
        .filter((_, i) => enabled[i])

    let max = Math.max(...objs.map(i => i.Value))
    return objs.map(item => ({ ...item, Value: item.Value / max }))
}

export const sendNormalized = state => {
    if (state.heatmap.data === undefined) return null;

    let norm = selectNormalized(state)
    return () => emit({ type: 'visualizeHeatmap', GradientScheme: gradients[state.gradient], Objects: norm })
}

export default heatmapSlice.reducer