import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { emitMsg } from '../websocket/websocketSlice'
import { selectCurrent, selectSlideCount, changeSlide } from './presentationSlice'

const SlideControls = () => {
    let dispatch = useDispatch()

    let current = useSelector(selectCurrent)
    let count = useSelector(selectSlideCount)

    let onClick = i => () => {
        dispatch(changeSlide(i))
        emitMsg("changeSlide", i)
    }
    
    return (
        <span className="btn-group">
            <button
                className="btn btn-secondary" disabled={current <= 0} onClick={onClick(current - 1)}
                style={{ width: "100px" }}
            >
                Previous
            </button>

            <button
                className="btn btn-secondary" disabled={current >= count - 1} onClick={onClick(current + 1)}
                style={{ width: "100px" }}
            >
                Next
            </button>
        </span>
    )
}

export default SlideControls