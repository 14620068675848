import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { selectBehaviour, selectType, selectContext } from '../../behaviourControlsSlice'
import { triggerBehaviour, askQuestions } from '../../../classState/studentsSlice'
import { unsetBehaviour } from '../../behaviourControlsSlice'

const SendBehaviour = () => {
    let dispatch = useDispatch()
    let behaviour = useSelector(selectBehaviour)
    let type = useSelector(selectType)
    let questionContext = useSelector(selectContext("askQuestion"))

    const onClick = () => {
        let action

        if (behaviour === "AskQuestion") {
            let qs = Object.keys(questionContext).map(
                k => ({ id: k, question: questionContext[k] })
            ).filter(q => q.question)

            action = askQuestions({
                questions: qs.map(q => q.question),
                students: qs.map(q => q.id)
            })
        } else {
            action = triggerBehaviour({ behaviour, type })
        }
        
        dispatch(action)
        dispatch(unsetBehaviour())
    }

    return (
        <button className="btn btn-success ml-3" onClick={onClick}>Trigger Behaviour</button>
    )
}

export default SendBehaviour