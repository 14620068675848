import React from 'react'
import * as Icon from 'react-feather'
import { useSelector } from 'react-redux'
import { emitMsg } from '../websocket/websocketSlice'
import { selectPaused } from './replaySlice'

const PlayButton = () => {
    let replayPaused = useSelector(selectPaused)

    return (
        <button class="close" onClick={() => emitMsg("replayState", replayPaused ? "play" : "pause")}>
            {replayPaused ? <Icon.Pause /> : <Icon.Play /> }
        </button>
    )
}

export default PlayButton